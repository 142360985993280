<template>
  <div class="events-list">

    <!-- Authors Table -->
    <a-row :gutter="24" type="flex">

      <!-- Authors Table Column -->
      <a-col :span="24" class="mb-24">

        <a-card :bordered="true" class="header-solid h-full" >
          <template #title>
            <a-row type="flex" align="middle">
              <a-col :span="24" :md="12">
                <h5 class="font-semibold m-0">
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>Reset All</span>
                    </template>
                  <a-button type="link" @click="resetTable" class="icon-btn" style="color: black">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
                         stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                         class="feather feather-refresh-cw">
                      <polyline points="23 4 23 10 17 10"></polyline>
                      <polyline points="1 20 1 14 7 14"></polyline>
                      <path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15"></path>
                    </svg>
                  </a-button>
                  </a-tooltip>
                  {{capitalizeFirstLetter(status)}} Events List {{pagination.total?'('+pagination.total+')':''}}
                </h5>
              </a-col>
              <a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
                  <router-link :to="{name:'Create Event'}">
                <a-button type="primary">
                  <a-icon type="plus-circle" theme="outlined"/>
                  ADD NEW
                </a-button>
                  </router-link>
              </a-col>
              <FormModal v-if="showModal" :visible="showModal" :selectedItem="selectedItem" :editMode="editMode"
                         @formSuccess="formSuccess" @cancel="cancelForm"></FormModal>
            </a-row>
            <a-row type="flex" align="middle" style="margin-top: 12px">

              <a-col :span="12"></a-col>
              <a-col :span="12"  style="display: flex; align-items: center; justify-content: flex-end">
                <a-input-search placeholder="Search Event Title" v-model="search" @input="loadingSearch=true,pagination.current=1,$router.push({name:'Events',query:{search:search,status:$route.query.status,user:$route.query.user,collection:$route.query.collection,startDate:$route.query.startDate,endDate:$route.query.endDate,expand:$route.query.expand}})" :loading="loadingSearch" />
              </a-col>
            </a-row>

          </template>
          <a-form  class="ant-advanced-search-form" :form="form" @submit="handleSearch">
            <a-row :gutter="24" type="flex" align="middle">

<!--              <a-col v-if="expand"-->
<!--                  :span="8"-->
<!--              >-->
<!--                <a-form-item label="Created Date (Start - End)">-->
<!--                  <a-range-picker @change="onChange" v-decorator="[-->
<!--          'date',-->
<!--            ]"/>-->

<!--                </a-form-item>-->
<!--              </a-col>-->
              <a-col :span="8" v-if="expand">
                <a-form-item label='User'>

                <a-select
                    style="width: 100%"
                    show-search
                    allow-clear
                    placeholder="Created By"
                    option-filter-prop="children"
                    :filter-option="filterUserOption"
                    :loading="loadingUsers"
                    v-model="user"
                    @change="pagination.current=1,$router.push({name:'Events',query:{search:$route.query.search,status:$route.query.status,user:user,collection:$route.query.collection,startDate:$route.query.startDate,endDate:$route.query.endDate,expand:$route.query.expand}})"
                >
                  <a-select-option  :label="user.name" :value="user._id" v-for="(user,index) in getAllAdminUsers" :key="index">
                                  <span style="margin-right: 5px;align-items: center" role="img" >
                                     <a-avatar v-if="user.imageUrl" slot="avatar" :src="user.imageUrl" />
        <a-avatar v-else slot="avatar" style="background-color: #1890ff">{{ user.name|getNameForAvatar}}</a-avatar>
      </span>
                    {{user.name}}
                  </a-select-option>
                </a-select>
                </a-form-item>
              </a-col>

<!--              <a-col v-if="expand"-->
<!--                  :span="8"-->
<!--              >-->
<!--                <a-form-item label="Schedule Date (Start - End)">-->
<!--                  <a-date-picker style="width: 100%" v-decorator="[-->
<!--          'end_date',-->
<!--          { rules: form.getFieldValue(start_date)?[{ required: true, message: 'Please select a date' }]:'',-->
<!--           },]"/>-->

<!--                </a-form-item>-->
<!--              </a-col>-->
              <a-col v-if="expand"
                  :span="8"
              >
              <a-form-item label='Collection'>
                <a-select
                    show-search
                    allow-clear
                    placeholder="Select a collection"
                    option-filter-prop="children"
                    :filter-option="filterOption"
                    :loading="loadingCategories"
                    v-model="collection"
                    @change="pagination.current=1,$router.push({name:'Events',query:{search:$route.query.search,status:$route.query.status,user:$route.query.user,collection:collection,startDate:$route.query.startDate,endDate:$route.query.endDate,expand:$route.query.expand}})"
                >
                  <a-select-option  :label="cat.title" :value="cat._id" v-for="(cat,index) in getAllCategories" :key="index">
                                  <span style="margin-right: 5px;align-items: center" role="img" >
                <a-avatar shape="square" :src="url+cat.image"></a-avatar>
      </span>
                    {{cat.title}}
                  </a-select-option>
                </a-select>
              </a-form-item>
              </a-col>
              <a-col v-if="expand"
                     :span="8"
              >
                <a-form-item label="Scheduled Date (Start - End)">
                  <a-range-picker @change="onChange"
                                  :default-value="start_date&&end_date
                                  ?[moment(start_date, dateFormat), moment(start_date, dateFormat)]:['','']"
                  />

                </a-form-item>
              </a-col>
<!--              <a-col-->
<!--                  :span="8"-->
<!--                  v-if="expand"-->
<!--              >-->
<!--                <a-form-item>-->
<!--                  <a-button type="primary" html-type="submit">-->
<!--                    Search-->
<!--                  </a-button>-->
<!--                  <a-button :style="{ marginLeft: '8px' }" @click="handleReset">-->
<!--                    Clear-->
<!--                  </a-button>-->
<!--                </a-form-item>-->
<!--              </a-col>-->
            </a-row>
            <a-row>
              <a-col :span="24" :style="{ textAlign: 'right' }">

                <a :style="{ marginLeft: '8px', fontSize: '12px' }" @click="toggle">
                  {{ expand ? 'Less' : 'More' }} Search Options <a-icon :type="expand ? 'up' : 'down'" />
                </a>
              </a-col>
            </a-row>
          </a-form>

          <a-radio-group default-value="physical" @change="pagination.current=1,$router.push({name:'Events',query:{status:status,search:$route.query.search,user:$route.query.user}})" button-style="outline" style="margin-bottom:10px" v-model="status">
            <a-radio-button value="All">
              All
            </a-radio-button>
            <a-radio-button value="published">
              Published
            </a-radio-button>
            <a-radio-button value="draft">
              Draft
            </a-radio-button>
            <a-radio-button value="inactive">
              Inactive
            </a-radio-button>
            <a-radio-button value="deleted">
              Deleted
            </a-radio-button>
          </a-radio-group>
          <template>
            <a-list :loading="loading" item-layout="vertical" size="large" :pagination="pagination" :data-source="listData">

            <a-list-item slot="renderItem" key="item.title"  slot-scope="item, index">
              <template
                  slot="actions" style="margin-left: 5px;">
                {{ item.albumsCount?item.albumsCount:'No' }} {{ item.albumsCount==1?'Album':'Albums' }}
              </template>
              <template
                  slot="actions" style="margin-left: 5px;">
                {{ item.videosCount?item.videosCount:'No' }} {{ item.videosCount==1?'Video Clip':'Video Clips' }}
              </template>
              <template
                  slot="actions" style="margin-left: 5px;">
                <span v-if="item&&$options.filters.countTickets(item)"> {{item | countTickets}} Tickets &nbsp;&nbsp;<router-link :to="{

        name: 'Edit Event | Schedules',
        params: { id: item._id }
      }">View</router-link></span>
                <span v-else style="color: #f5222d"> No Tickets&nbsp;&nbsp;<router-link :to="{

        name: 'Edit Event | Schedules',
        params: { id: item._id }
      }">Add Now</router-link></span>
              </template>
<!--              <template v-if="item.status=='Published'||item.status=='published'||item.status=='Inactive'||item.status=='inactive'" slot="actions" style="margin-left: 5px;">-->
<!--              4 Bookmarks-->
<!--            </template>-->
<!--              <template v-if="item.status=='Published'||item.status=='published'||item.status=='Inactive'||item.status=='inactive'" slot="actions" style="margin-left: 5px;">-->
<!--              No Reports-->
<!--            </template>-->
              <template slot="actions" style="margin-left: 5px;">
              Modified {{ moment(item.updatedAt).fromNow() }}
            </template>

              <a-list-item-meta style="position: relative">
                <div slot="title" style="display: flex">
                <router-link class="event-title" slot="title" :to="{

        name: 'Edit Event | Publish/Summary',
        params: { id: item._id }
      }" >
                  <span @click="$store.commit('RESET_SELECTED_EVENT')">
                     {{ item.title }}</span> <span style="color:rgba(0, 0, 0, 0.45);font-weight: 600;">#{{item.eventNumber}}</span>
                </router-link>

                <div style="margin-left: 10px">
                  <a-dropdown>
                    <a-icon type="more" :style="{'cursor':'pointer','font-size':'18px','font-weight':'bold'}" />
                    <a-menu slot="overlay" @click="">
                      <a-menu-item key="1"> <router-link target="_blank" :to="{

        name: 'Edit Event | Basic Info',
        params: { id: item._id }
      }" >
                      <span @click="$store.commit('RESET_SELECTED_EVENT')">
                     Edit</span> </router-link></a-menu-item>
<!--                      <a-menu-item key="2" v-if="item.status=='draft'||item.status=='Draft'"> Delete </a-menu-item>-->
<!--                      <a-menu-item key="3" v-if="item.status=='published'||item.status=='Published'"> Inactive </a-menu-item>-->
                    </a-menu>
                  </a-dropdown>


                </div>
                </div>
              </a-list-item-meta>

              <p style="color: rgba(0, 0, 0, 0.45);
    font-size: 15px;font-weight: 600;
    margin-bottom: 0.7rem;"

              >
               <span v-if="item.schedules&&item.schedules.length" style="margin-right:20px">
                  <a-icon type="calendar" style="margin-right: 8px" />
                <span v-if="new Date(item.schedules[0].startDate).toDateString()!=new Date(item.schedules[0].endDate).toDateString()">{{ item.schedules[0].startDate | moment("D MMM YYYY, hh:mm A") }} - {{ item.schedules[0].endDate | moment("D MMM YYYY, hh:mm A") }}</span>
                <span v-else>{{ item.schedules[0].startDate | moment("D MMM YYYY, hh:mm A") }} - {{ item.schedules[0].endDate | moment("hh:mm A") }}</span>
                 <span v-if="item.schedules.length>1" style="color: rgba(0, 0, 0, 0.85);font-size: 14px"> +{{item.schedules.length-1}} more</span>
                 </span>

              </p>
              <div class="table-avatar-info" style="align-items: center">
                <a-avatar shape="square" :src="url+item.collectionId.image" style="margin-right: 8px;"></a-avatar>
                <div class="avatar-info">
                  <h6>{{ item.collectionId.title }}</h6>
                </div>

                <span style="margin:0 5px 3px 20px" v-if="item.schedules.length==1">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg>
                <!--                <a-icon type="global" style="margin:0 5px 3px 20px" />-->
                </span>
<!--                  <div class="avatar-info" v-if="item.locationType=='physical'">-->
<!--                  <h6>{{item.singleSchedule.post_code + ', ' + item.singleSchedule.district + ', ' + item.singleSchedule.country-->
<!--                    }}</h6>-->

<!--                </div>-->
                <div class="avatar-info" v-if="item.schedules.length==1">
                  <h6>{{item.schedules[0].locationType=='physical'?(item.schedules[0].post_code + ', ' + item.schedules[0].district + ', ' + item.schedules[0].country):(item.schedules[0].locationType=='online'?'Online Event':'Not Announced Yet')
                    }}</h6>

                </div>
               <span v-if="item.schedules.length>1" style="margin-left:20px">
                  <a-tag size="small" >
                    Multiple Schedules
                  </a-tag>
               </span>
                <span v-if="item.schedules.length<1" style="margin-left:20px">
                   <a-tag size="small" color="red">
                  Schedules not added
                </a-tag>
                  </span>
              </div>
              <p style="color: rgba(0, 0, 0, 0.45);
    font-size: 14px;font-weight: 600;
    margin: 0.8rem 0 0.5rem 5px;">
                Created by {{ item.user.name }} on {{ item.createdAt | moment("MMM D YYYY, hh:mm A") }}
                <a-tag class="tag-status ant-tag-primary" :class="{'ant-tag-success':item.status=='published'||item.status=='Published','ant-tag-danger':item.status=='deleted'||item.status=='Deleted','ant-tag-warning':item.status=='Inactive'||item.status=='inactive'}" style="margin-left: 10px">
                  {{ capitalizeFirstLetter(item.status) }}
                </a-tag>
              </p>
<!--              <div                   slot="extra"-->
<!--              ></div>-->
              <img
                  slot="extra"
                  alt="logo"
                  style="height: auto;width:auto;max-width:272px;max-height: 190px;
    border-radius: 5px;"
                  :src="item.image?(item.image.type=='image_source'?item.image.sourceUrl:url+item.image.uploadPath):'images/no-image.jpg'"
              />
            </a-list-item>
          </a-list>
          </template>


        </a-card>


      </a-col>

    </a-row>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import moment from 'moment';

import {mapGetters} from 'vuex'
import FormModal from "./FormModal";
const listData = [];

export default {
  components: {FormModal},
  data() {
    this.fetchEventDebounce = debounce(this.fetchEventDebounce, 800);
    return {
      dateFormat : 'YYYY-MM-DD',
      expand: false,
      start_date: '',
      end_date: '',
      collection: undefined,
      form: this.$form.createForm(this, { name: 'advanced_search' }),
      moment,
      loadingSearch:false,
      search:'',
      listData,
      pagination: {
        onChange: page => {
          this.$router.push({name:'Image Albums',query:{page:page,search:this.$route.query.search,status:this.$route.query.status}})
          this.handleTableChange(page)
        },
        current:this.$route.query.page?this.$route.query.page:1,
        pageSize: 10,
      },
      status:'All',
      url: process.env.VUE_APP_SERVER_URL,
      filteredInfo: null,
      sortedInfo: null,
      searchText: "",
      searchInput: null,
      selectedItem: {},
      showModal: false,
      editMode: false,
      data: [],
      loadingCategories:false,
      loadingUsers:false,
      user:undefined,
      loading: false,
      authorsHeaderBtns: 'all',
      queryData: params => {
        return this.$store.dispatch("ALL_EVENTS", {params: params});
      }
    };
  },
  watch:{
    '$route.query.search'(){
      this.fetchEventDebounce()
    },
    '$route.query.status'(){
      this.fetch()
    },

    '$route.query.user'(){
        this.fetch()
    },
    '$route.query.collection'(){
        this.fetch()
    },
    '$route.query.startDate'(){
        this.fetch()
    },
    '$route.query.endDate'(){
        this.fetch()
    }
  },
  mounted() {
    this.status=this.$route.query.status?this.$route.query.status:'All'
    this.search=this.$route.query.search?this.$route.query.search:''
    this.user=this.$route.query.user?this.$route.query.user:undefined
    this.collection=this.$route.query.collection?this.$route.query.collection:undefined
    // this.expand=this.$route.query.expand
    this.start_date = this.$route.query.startDate?this.$route.query.startDate:null
    this.end_date = this.$route.query.endDate?this.$route.query.endDate:null

    this.$forceUpdate()
    this.fetch();
    this.allFetchCategories();
    this.allFetchAdminUsers();

  },
  computed: {
    // count() {
    //   return this.expand ? 4 : 1;
    // },
    getAllCategories(){
      return this.$store.getters.getAllCategories
    },
    getAllAdminUsers(){
      return this.$store.getters.getAllAdminUsers
    },
  },
  methods: {
    onChange(date, dateString) {
    this.pagination.current=1;
      this.start_date=dateString[0];
      this.end_date=dateString[1];
    this.$router.push({
      name:'Events',query:{
        search:this.$route.query.search,
        status:this.$route.query.status,
        user:this.$route.query.user,
        collection:this.$route.query.collection,
        startDate:this.start_date,
        endDate:this.end_date,
        expand:this.$route.query.expand
      }})

    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[1].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    filterUserOption(input, option) {
      return (
          option.componentOptions.children[1].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    allFetchCategories(){
      this.loadingCategories=true
      this.$store.dispatch("FETCH_ALL_COLLECTIONS")
          .then(()=>{
            this.loadingCategories=false
          })
    },
    allFetchAdminUsers(){
      this.loadingUsers=true
      this.$store.dispatch("FETCH_ALL_ADMIN_USERS")
          .then(()=>{
            this.loadingUsers=false
          })
    },
    // handleSearch(e) {
    //   e.preventDefault();
    //   this.form.validateFields((error, values) => {
    //     console.log('error', error);
    //     console.log('Received values of form: ', values);
    //   });
    // },
    //
    // handleReset() {
    //   this.form.resetFields();
    // },

    toggle() {
      this.expand = !this.expand;
      this.$router.push({name:'Events',query:{status:this.$route.query.status,search:this.$route.query.search,user:this.$route.query.user,expand:this.expand,collection:this.$route.query.collection,
          startDate:this.$route.query.start_date,
          endDate:this.$route.query.end_date}}).catch(()=>{})
    },
    fetchEventDebounce(){
      this.fetch()
    },
    handleTableChange(page) {
      const pager = {...this.pagination};
      pager.current = page;
      this.pagination = pager;
      // this.filteredInfo = filters;
      // this.sortedInfo = sorter;
      this.fetch({
        results: this.pagination.pageSize,
        page: this.pagination.current,
        // sortField: sorter.field,
        // sortOrder: sorter.order,
        // ...filters,
      });
    },
    fetch(params = {}) {
      this.loading = true;
      params.status=this.status
      params.search=this.search
      params.start_date=this.start_date
      params.end_date=this.end_date
      params.collection=this.collection
      params.user=this.user
      this.queryData({
        results: this.pagination.pageSize ? this.pagination.pageSize : 10,
        page: this.pagination.current ? this.pagination.current : 1,
        ...params,
      }).then(({data}) => {
        const pagination = {...this.pagination};
        // Read total count from server
        // pagination.total = data.totalCount;
        pagination.total = data.total;
        this.loading = false;
        this.listData = data.results;
        this.pagination = pagination;
      })
      .finally(()=>{
        this.loadingSearch=false
      });
    },
    showDelete(id) {
      this.$confirm({
        title: 'Are you sure want to delete this collection?',
        content: 'This process is irreversible. Are you sure want to continue?',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: () => {
          this.$store.dispatch("DELETE_COLLECTION", id)
              .then((res) => {
                this.$message.success(res.data.message);
                this.fetch()
              })
              .catch((err) => {
         //       this.$message.error(err.response.data.message);
              })
              .finally(() => {
              });
        },
      });
    },
    showEditForm(row) {
      this.$router.push({
        name: 'Edit Event | Basic Info',
        params: { id: row._id }
      });

    },
    formSuccess() {
      this.showModal = false;
      this.editMode = false
      this.selectedItem = {}
      this.fetch()
    },
    cancelForm() {
      this.showModal = false;
      this.editMode = false
      this.selectedItem = {}
    },
    handleSearch(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          if(values.date){
            this.start_date=values.date[0]
            this.end_date=values.date[1]
          }
          this.collection=values.collection
        }
      })
    },
    handleReset() {
      this.start_date=''
      this.end_date=''
      this.collection=undefined
      this.user=undefined
      this.search=''
      this.expand=false
    },
    resetTable() {
      this.handleReset()
      // this.searchText = "";
      // this.filteredInfo = null;
      // this.sortedInfo = null;
      // this.pagination.current = 1;
      // this.pagination.pageSize = 10;
      // Object.assign(this.pagination,{})
      // this.fetch()
      this.$router.push({name:'Events'})
    },
    capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
  },
};
</script>
<style >
.events-list .editable-row-operations a {
  margin-right: 8px;
}

.events-list .custom-filter-dropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.events-list .highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
.events-list .ant-radio-group .ant-radio-button-wrapper{
  padding: 0 32px;
}
.events-list .ant-radio-button-wrapper:focus-within{
  box-shadow: none;
}
.events-list .ant-list-vertical .ant-list-item-meta{
  margin-bottom: 0;
}
.events-list .table-avatar-info .avatar-info h6{
  color: rgba(0, 0, 0, 0.65);
  font-weight: 600;
}
.events-list  .ant-spin-nested-loading{
  min-height: 300px!important;
}
.events-list .ant-list-item-action{
  font-weight: 600;
}
.events-list .ant-list-item-action > li{
  font-weight: 600!important;
}
.events-list .ant-list-vertical .ant-list-item-action{
  margin-left: 5px;
}
.events-list .ant-list-vertical .ant-list-item-meta-title{
  margin-bottom: 6px;
}.events-list .ant-card-head-title span{
  margin-left: 0px;
}
/*.events-list .ant-list-vertical .ant-list-item{*/
/*  width: 50%;*/
/*  display: inline-block;*/
/*  padding-left: 8px;*/
/*  padding-right: 8px;*/
/*}*/
.event-title{
  color: rgba(0, 0, 0, 0.65);
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s
}

.fade-enter,
.fade-leave-to {
  opacity: 0
}
</style>
