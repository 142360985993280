<template>
  <div>
    <a-modal
        :destroyOnClose="true"
        :confirm-loading="confirmLoading"
        :dialog-style="{ top: '20px' }"
        :visible="visible" :title="title" okText="Submit" @ok="handleOk" @cancel="cancelForm">
      <a-form layout='vertical' :form="form">
        <a-form-item label='Title'>
          <a-input
              v-decorator="[

              'title',
              {initialValue:selectedItem.title,
              }
            ]"
          />
        </a-form-item>

        <a-form-item label='Image'>
          <a-upload
              :remove="handleRemove" :before-upload="beforeUpload"
              :default-file-list="fileList"
              list-type="picture"
              v-decorator="[
              'image',
              {
                initialValue:fileList,
              }
            ]"
          >
            <a-button :disabled="fileList.length >0">
              <a-icon type="upload"/>
              Upload
            </a-button>
          </a-upload>
        </a-form-item>
        <a-form-item label='Description'>
          <a-input
              :auto-size="{ minRows: 3, maxRows: 6 }"
                  type='textarea'
              v-decorator="['description',{
                initialValue:Object.keys(selectedItem).length!=0?selectedItem.description:'',
              }]"
          />
        </a-form-item>
        <a-form-item class='collection-create-form_last-form-item'>
          <a-radio-group
              v-decorator="[
              'status',
              {
                initialValue: Object.keys(selectedItem).length!=0?selectedItem.status.toString():'true',
              }
            ]"
          >
            <a-radio value='true'>Active</a-radio>
            <a-radio value='false'>In-active</a-radio>
          </a-radio-group>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
export default {
  props: ['editMode', 'visible','selectedItem'],
  data() {
    return {
      url: process.env.VUE_APP_SERVER_URL,
      fileList: [],
      confirmLoading: false,
      form: this.$form.createForm(this),
      id:''

      // visible: false,
    };
  },
  computed: {
    title() {
      return this.editMode ? 'Edit Collection' : 'Create New Collection'
    }
  },
  methods: {
    handleOk(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          const formData = new FormData();
          formData.append('image', this.fileList[0]);
          formData.append('title', !!values.title?values.title:'')
          formData.append('description', !!values.description?values.description:'')
          formData.append('status', values.status)
          this.confirmLoading = true
          if (this.editMode){
            this.$store.dispatch("UPDATE_COLLECTION", {formData:formData,id:this.id})
                .then((res) => {
                  this.fileList=[]
                  this.form.resetFields()
                  this.$message.success(res.data.message);
                  this.$emit('formSuccess')
                })
                .catch((err) => {
                  // this.$message.error(err.response.data.message);
                })
                .finally(() => {
                  this.confirmLoading = false
                });
          }
          else {
            this.$store.dispatch("CREATE_COLLECTION", formData)
                .then((res) => {
                  this.fileList=[]
                  this.form.resetFields()
                  this.$message.success(res.data.message);
                  this.$emit('formSuccess')
                })
                .catch((err) => {
                  // this.$message.error(err.response.data.message);
                })
                .finally(() => {
                  this.confirmLoading = false
                });

          }

        }
      });
    },
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    beforeUpload(file) {
      if (this.fileList.length < 1)
        this.fileList = [...this.fileList, file];

      return false;
    },
    cancelForm(){
      this.fileList=[];
      this.form.resetFields();
      this.confirmLoading=false;
      this.$emit('cancel')
    },
  },
  mounted() {
    // setTimeout(()=>{
      if (this.editMode&&Object.keys(this.selectedItem).length!=0){
        this.id=this.selectedItem._id
      this.fileList= [
        {
          uid: '-1',
          name: this.selectedItem.image_name,
          status: 'done',
          url: this.url+this.selectedItem.image,
          thumbnail: this.url+this.selectedItem.image,
        },
      ]
    }
    // },100)

      // this.form={...this.selectedItem}
  }
};
</script>
<style>
.blocked {
  pointer-events: none;
  opacity: 0.5;
}
</style>